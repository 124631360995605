<div
  class="accordion"
  id="accordionExample"
  *ngIf="role == 'SGRE Admin' || role == 'Sales Rep'"
>
  <div class="card">
    <div class="card-header d-flex align-items-center" id="headingOne"
      data-toggle="collapse"
      data-target="#collapseFour"
      aria-expanded="false"
      aria-controls="collapseFour">
      <h2 class="mb-0 flex-grow-1">
        <button
          class="btn btn-link btn-block text-left pl-0"
          type="button"
        >
          {{ "orderHistoryPage.legalEntityWiseSales" | cxTranslate }}
        </button>
      </h2>
      <i class="sg-icon sg-icon-arrow_up_down text-dark mx-3 cursor-pointer toggling-arrow-icon"></i>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingOne">
      <div class="card-body">
        <powerbi-report
          [embedConfig]="reportConfig"
          [cssClassName]="reportClass"
        >
        </powerbi-report>
      </div>
    </div>
  </div>
</div>
